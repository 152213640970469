<script>
import { HorizontalBar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              callback(value) {
                if (Math.floor(value) === value) return value;
                return null;
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            }
          }]
        }
      })
    }
  },
  mounted () {
    return this.renderChart(this.chartData, this.options);
  }
};
</script>
