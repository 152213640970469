<template>
  <settings-dialog
    :value="value"
    title="Personalize your own dashboard"
    :loading="isLoading"
    @input="$emit('input', $event)"
    @save="saveSettings"
  >
    <p>
      Select the keymetrics you wish to see at your dashboard
    </p>
    <v-row
      v-ruid="'key-metric-checkboxes'"
      no-gutters
    >
      <v-col
        cols="12"
        md="6"
      >
        <h4>Phases</h4>
        <v-checkbox
          v-for="(keyMetric, index) of phaseKeyMetrics"
          :key="index"
          v-model="selectedKeyMetrics"
          v-ruid="`keymetrics-setting-${keyMetric.value}`"
          :value="keyMetric.value"
          :label="keyMetric.text"
          class="mt-0"
          color="blue"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <h4 v-if="company.features[FEATURE_NAME.CAMPAIGNS]">
          Active Campaigns
        </h4>
        <v-checkbox
          v-for="(keyMetric, index) of campaignKeyMetrics"
          :key="index"
          v-model="selectedKeyMetrics"
          :value="keyMetric.value"
          :label="keyMetric.text"
          class="mt-0"
          color="blue"
        />
      </v-col>
    </v-row>
  </settings-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { DashboardUtils } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { campaignService, userService } from '@/services';
import SettingsDialog from './SettingsDialog';

export default {
  components: {
    SettingsDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedKeyMetrics: [],
      isLoading: false,
      FEATURE_NAME
    };
  },
  computed: {
    ...mapGetters(['campaigns', 'userSettings', 'company']),
    phaseKeyMetrics() {
      return Object.keys(DashboardUtils.phases).map((key) => ({
        type: 'phase',
        value: DashboardUtils.phases[key].name,
        text: DashboardUtils.phases[key].title,
        icon: DashboardUtils.phases[key].icon
      }));
    },
    campaignKeyMetrics() {
      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        return this.enabledCampaigns.map((campaign) => ({
          type: 'campaign',
          value: campaign.id,
          text: campaign.title,
          icon: 'campaign'
        }));
      }
      return [];
    },
    availableKeyMetrics() {
      return [
        ...this.phaseKeyMetrics,
        ...this.campaignKeyMetrics
      ];
    },
    // Filters the campaigns and returns only the ones that are enabled
    enabledCampaigns() {
      return this.campaigns.filter((campaign) => !campaign.disabled);
    },
    // Maps the selected key metrics to the object needed for saving it to the databse
    selectedKeyMetricsMapped() {
      // filter available key metrics for the selected metrics. With this way, the order
      // of the key metrics will always be the same
      return this.availableKeyMetrics
        .filter((keyMetric) => this.selectedKeyMetrics.find((k) => k === keyMetric.value));
    }
  },
  watch: {
    value() {
      if (this.value) {
        // Refresh campaigns
        campaignService.getCampaigns();

        // apply the user settings if the user already has personalized the key-metrics
        if (this.userSettings && this.userSettings.keyMetrics) {
          this.selectedKeyMetrics = this.userSettings.keyMetrics
            .filter((selectedKeyMetric) => selectedKeyMetric.type === 'phase'
              || this.enabledCampaigns.find((campaign) => campaign.id === selectedKeyMetric.value))
            .map((selectedKeyMetric) => selectedKeyMetric.value);
        } else {
          // apply the default settings if the user has not personalized the key-metrics
          this.selectedKeyMetrics = Object.keys(DashboardUtils.phases)
            .map((key) => DashboardUtils.phases[key].name);
        }
      }
    }
  },
  methods: {
    // Saves the selected key metrics to the database
    saveSettings() {
      // validate key metrics
      if (this.selectedKeyMetrics.length > 12) {
        this.$store.dispatch('showSnackBar', { text: 'You can select only up to 12 key metrics' });
      } else if (this.selectedKeyMetrics.length < 3) {
        this.$store.dispatch('showSnackBar', { text: 'You have to select at least 3 key metrics' });
      } else {
        this.isLoading = true;
        userService.updateUserSettings({
          keyMetrics: this.selectedKeyMetricsMapped
        }).then(() => {
          this.$emit('input', false);
        }).catch(() => {
          this.$store.dispatch('showSnackBar', { text: 'An error occurred while updating the settings.' });
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card-text {
  max-height: 500px;
  overflow-y: auto;
}
</style>
