<template>
  <div>
    <h4>Projects per phase</h4>
    <label>Amount of projects in each phase during the selected time period</label>
    <app-chart
      ref="chart"
      type="bar"
      :chart-data="projectsData"
      class="bar-chart"
      :loading="isLoading.projects"
    />
  </div>
</template>

<script>
import {
  REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX
} from '@kickbox/common-util';
import { mapGetters } from 'vuex';
import AppChart from '@kickbox/common-admin/src/components/statistic-dashboard/charts/AppChart';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    AppChart
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projectPhases: [REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX],
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    // Creates the object needed for the char.js bar chart
    projectsData() {
      const data = [];
      const labels = [];
      // Loop through each phase and get the number of projects in this phase
      this.projectPhases.forEach((phase) => {
        data.push(utils.getProjectsByPhase(
          phase, this.dateRange[0], this.dateRange[1]
        ).length);
        labels.push(utils.getFullPhaseName(phase));
      });
      return {
        labels,
        datasets: [
          {
            borderWidth: 0,
            barThickness: 16,
            data,
            backgroundColor: [
              utils.phases[REDBOX_IDEA.name].color,
              utils.phases[REDBOX_FUNDING.name].color,
              utils.phases[BLUEBOX.name].color,
              utils.phases[GOLDBOX.name].color
            ],
          }
        ]
      };
    }
  },
  watch: {
    fullWidth() {
      // force the chart to rerender after the with of the container has changed
      // eslint-disable-next-line no-underscore-dangle
      this.$refs.chart.$data._chart.resize();
    }
  }
};
</script>

<style lang="scss" scoped>
.bar-chart {
  height: 250px;
}
</style>
