<template>
  <div class="top-services">
    <h4>Top Services</h4>
    <v-data-table
      :headers="tableHeaders"
      :items="topServices"
      :items-per-page="5"
      :hide-default-footer="isLoading.other"
      class="transparent"
    >
      <template
        v-if="isLoading.transactions"
        #body
      >
        <table-skeleton-loader
          :headers="tableHeaders"
          :rows="5"
        />
      </template>
      <template
        v-else
        #items="props"
      >
        <td class="font-weight-bold">
          {{ props.item.rank }}
        </td>
        <td>
          {{ props.item.name }}
        </td>
        <td class="font-weight-bold">
          {{ props.item.amount }}
        </td>
      </template>
      <template #no-data>
        <p>No accepted requests within time selected</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';
import TableSkeletonLoader from '@kickbox/common-admin/src/components/TableSkeletonLoader';

export default {
  components: {
    TableSkeletonLoader
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableHeaders: [{
        text: 'Rank',
        value: 'rank',
        width: '90'
      }, {
        text: 'Service',
        value: 'name',
        width: '300'
      }, {
        text: 'Accepted Requests',
        value: 'amount',
        width: '175'
      }]
    };
  },
  computed: {
    ...mapGetters(['dashboardTransactions', 'isLoading']),
    // Create an array with all services, sorted by amount of requests
    topServices() {
      // Create an object with the service name as key and the amount of requests as value
      const servicesCount = this.serviceTransactions.reduce((acc, transaction) => {
        acc[transaction.offerTitle] = (acc[transaction.offerTitle] || 0) + 1;
        return acc;
      }, {});

      // Convert the services object to an array to sort it after the amount of requests
      return Object.entries(servicesCount)
        .map((skillDataArray) => ({
          name: skillDataArray[0],
          amount: skillDataArray[1]
        }))
        .sort((a, b) => b.amount - a.amount)
        // Map a second time after sorting to add the rank of the skill
        .map((skillData, index) => ({
          ...skillData,
          rank: index + 1
        }));
    },
    // All transactions that requested a service within the time selected
    serviceTransactions() {
      return this.dashboardTransactions
        .filter((transaction) => transaction.type === TRANSACTION_TYPE.REQUEST
          && transaction.createdAt.isBetween(this.dateRange[0], this.dateRange[1], 'day', '[]'));
    }
  }
};
</script>

<style lang="scss">
.top-services .v-table, .top-services .v-datatable .v-datatable__actions{
  background-color: transparent;
}
</style>
