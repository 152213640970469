<template>
  <v-skeleton-loader
    v-if="loading"
    type="image"
  />
  <bar-chart
    v-else-if="type === 'bar'"
    v-bind="$attrs"
  />
  <line-chart
    v-else-if="type === 'line'"
    v-bind="$attrs"
  />
  <pie-chart
    v-else-if="type === 'pie'"
    v-bind="$attrs"
  />
</template>

<script>
import BarChart from './BarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';

export default {
  components: {
    BarChart,
    LineChart,
    PieChart
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>
