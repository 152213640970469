<template>
  <v-row>
    <!-- Given Coins -->
    <v-col
      cols="12"
      md="3"
    >
      <single-number
        :number="totalGivenCoins"
        text="Given Coins"
        ruid="givenCoins"
        tooltip="Sum of all coins received by projects. Includes coins received when starting
        the RedBox Idea phase and also coin additions and deductions done by the Admins."
        :loading="isLoading.transactions"
      />
    </v-col>
    <!-- Spend Coins -->
    <v-col
      cols="12"
      md="3"
    >
      <single-number
        :number="totalSpendCoins"
        text="Spent Coins"
        ruid="spentCoins"
        tooltip="Sum of all coins spent by projects.
        Includes coins spent on services or used up through coin conversion."
        :loading="isLoading.transactions"
      />
    </v-col>
    <!-- Average coins per project -->
    <v-col
      cols="12"
      md="4"
    >
      <single-number
        :number="averageCoinsPerProject"
        text="Average spent coins per project"
        ruid="avgCoinsPerProject"
        :tooltip="`Spent Coins (${totalSpendCoins || 0}) divided by the number of projects
        that started the RedBox Idea phase (${amountRedboxIdeaProjects}).`"
        :loading="isLoading.transactions"
      />
    </v-col>
  </v-row>
</template>

<script>
import { REDBOX_IDEA } from '@kickbox/common-util';
import { mapGetters } from 'vuex';
import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';
import SingleNumber from '@kickbox/common-admin/src/components/statistic-dashboard/charts/SingleNumber';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    SingleNumber
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    // Total Coins that are given to all projects
    totalGivenCoins() {
      return this.totalCoinsByTransactionType(TRANSACTION_TYPE.ADD_PROJECT_CREDIT)
        - this.totalCoinsByTransactionType(TRANSACTION_TYPE.REMOVE_PROJECT_CREDIT);
    },
    // Total Coins that the kickboxers effectively spend
    totalSpendCoins() {
      const transactions = this.transactionsInTimeSelected
        .filter((transaction) => transaction.type === TRANSACTION_TYPE.CONVERT_KICK_COINS
          || transaction.type === TRANSACTION_TYPE.REQUEST);
      return utils.getAmountOfCoins(transactions);
    },
    amountRedboxIdeaProjects() {
      const projects = utils.getProjectsByPhase(
        REDBOX_IDEA, this.dateRange[0], this.dateRange[1]
      );
      return projects.length;
    },
    averageCoinsPerProject() {
      if (!this.totalSpendCoins || !this.amountRedboxIdeaProjects) {
        return 0;
      }
      return this.totalSpendCoins / this.amountRedboxIdeaProjects;
    },
    // Get only the transactions created within the time selected
    transactionsInTimeSelected() {
      return utils.transactionsBetweenDates(this.dateRange[0], this.dateRange[1]);
    }
  },
  methods: {
    /**
     * Calculate the sum of all coins for a transaction type
     *
     * @param {string} transactionType
     */
    totalCoinsByTransactionType(transactionType) {
      const transactions = this.transactionsInTimeSelected
        .filter((transaction) => transaction.type === transactionType);
      return utils.getAmountOfCoins(transactions);
    }
  },
};
</script>
