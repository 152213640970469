<template>
  <div>
    <app-chart
      type="pie"
      :chart-data="chartData"
      :height="310"
      :loading="isLoading.skills"
    />
  </div>
</template>

<script>
import { KICKBOX_ROLES } from '@kickbox/common-util';
import { mapGetters } from 'vuex';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';
import AppChart from '@kickbox/common-admin/src/components/statistic-dashboard/charts/AppChart';

export default {
  components: {
    AppChart
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    // Data object for the chart.js pie chart
    chartData() {
      const users = utils.getUsersForRoleAndDateRange(
        KICKBOX_ROLES.EXPERT, this.dateRange[0], this.dateRange[1]
      );

      /**
       * Create an object with every skill and the amount a skill is selected
       * as key, value pair
       */
      const skillsObject = users.reduce((acc, user) => {
        user.skills.forEach((skill) => {
          acc[skill.name] = (acc[skill.name] || 0) + 1;
        });
        return acc;
      }, {});

      // Creates an array that is sorted by the amount of the skills
      const skillsData = Object.entries(skillsObject)
        .map((skillDataArray) => ({
          name: skillDataArray[0],
          amount: skillDataArray[1]
        }))
        .sort((a, b) => b.amount - a.amount);

      const labels = [];
      const data = [];

      // Adds the top 10 most used skills to the data and labels array
      for (let i = 0; i < 10; i += 1) {
        if (skillsData[i]) {
          labels.push(skillsData[i].name);
          data.push(skillsData[i].amount);
        }
      }

      return {
        labels,
        datasets: [
          {
            backgroundColor: utils.getColors(data.length),
            data
          },
        ]
      };
    }
  },
};
</script>
