<template>
  <div>
    <h4>{{ roleName }} per unit</h4>
    <app-chart
      type="pie"
      :chart-data="chartData"
      :height="310"
      :loading="isLoading.users"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { KICKBOX_ROLES } from '@kickbox/common-util';
import AppChart from '@kickbox/common-admin/src/components/statistic-dashboard/charts/AppChart';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    AppChart
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    role: {
      type: String,
      default: null
    },
    units: {
      type: Array,
      default: null
    },
    unitColors: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    // Returns the chart data for the chart.js line chart
    chartData() {
      const usersPerUnit = {};
      // Create an object that contains every unit with the amount of users as key value pair
      this.usersForTimeSelected.forEach((user) => {
        if (user.unit) {
          const count = usersPerUnit[user.unit.name];
          usersPerUnit[user.unit.name] = count ? count + 1 : 1;
        }
      });
      // Get the correct colors for every unit that is visible and save them to an array
      const unitColors = Object.keys(usersPerUnit).map((unit) => this.unitColors[unit]);

      // Only show users with no unit when all uses are displayed
      if (this.role === KICKBOX_ROLES.VIEWER) {
        usersPerUnit['No Unit (Viewer)'] = this.usersWithNoUnit.length;
      }

      return {
        labels: Object.keys(usersPerUnit),
        datasets: [
          {
            backgroundColor: unitColors,
            data: Object.values(usersPerUnit)
          },
        ]
      };
    },
    // Returns all users that are registered in the time selected and have the correct role
    usersForTimeSelected() {
      return utils.getUsersForRoleAndDateRange(
        this.role, this.dateRange[0], this.dateRange[1]
      );
    },
    // Returns the name of the role in plural for stat title
    roleName() {
      if (this.role === KICKBOX_ROLES.KICKBOXER) return 'Kickboxers';
      if (this.role === KICKBOX_ROLES.EXPERT) return 'Experts';
      return 'Users';
    },
    // Returns all users that have no unit
    usersWithNoUnit() {
      return this.usersForTimeSelected.filter((u) => !u.unit);
    }
  }
};
</script>
