<script>
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
export default {
  name: 'LineChart',
  extends: Line,
  mixins: [reactiveProp],
  props: {
    legend: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Default options.
     *
     * The legend is turned on, when the legend property is passed to this component
     */
    options() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: this.legend,
          align: 'start',
          labels: {
            usePointStyle: true,
          }
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              callback(value) {
                // Only return integer numbers for labels
                if (Math.floor(value) === value) return value;
                return null;
              }
            }
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              display: false
            }
          }]
        }
      };
    }
  },
  mounted () {
    return this.renderChart(this.chartData, this.options);
  }
};
</script>
