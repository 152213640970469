<template>
  <div class="mt-4 single-number">
    <v-skeleton-loader
      v-if="loading"
      align="center"
      class="mx-auto"
      :height="45"
      type="heading"
      :width="100"
    />
    <p
      v-else
      v-ruid="ruid"
      class="number mb-0"
    >
      {{ formattedNumber }}
    </p>
    <h4>
      {{ text }}
    </h4>
    <v-tooltip
      v-if="tooltip"
      bottom
      max-width="460px"
      class="d-inline-block ml-1"
    >
      <template #activator="{ on }">
        <label
          class=""
          v-on="on"
        >
          <v-icon color="primary">info</v-icon>
        </label>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: [Number, String],
      default: null
    },
    text: {
      type: String,
      default: null
    },
    ruid: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  computed: {
    /**
     * Insert ' as separator after every thousands.
     * Makes large Number much more readable
     */
    formattedNumber() {
      return new Intl.NumberFormat('en-US').format(Math.round(this.number));
    }
  }
};
</script>

<style lang="scss" scoped>

.single-number {
  width: 100%;
  text-align: center;
}

.number {
  font-size: 30px;
  font-weight: 100;
  color: black;
}
h4 {
  display: inline-block;
}
</style>
