<template>
  <v-row>
    <v-col
      cols="12"
      :md="fullWidth ? 3 : 12"
    >
      <single-number
        text="Accepted Requests"
        :number="serviceTransactions.length"
        ruid="bookedServices"
        :loading="isLoading.transactions"
      />
    </v-col>
    <v-col
      cols="12"
      :md="fullWidth ? 3 : 12"
    >
      <single-number
        text="Coins spent on services"
        :number="spentCoinsOnServices"
        ruid="coinsSpentOnServices"
        :loading="isLoading.transactions"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleNumber from '@kickbox/common-admin/src/components/statistic-dashboard/charts/SingleNumber';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    SingleNumber
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    // All transactions that requested a service within the time selected
    serviceTransactions() {
      return utils.serviceTransactionsBetweenDates(this.dateRange[0], this.dateRange[1]);
    },
    // Total Coins that are used of services
    spentCoinsOnServices() {
      return utils.getAmountOfCoins(this.serviceTransactions);
    },
  },
};
</script>
