<template>
  <v-container
    ref="pdfContainer"
  >
    <v-row>
      <v-col
        col="12"
      >
        <div class="dashboard">
          <projects-drawer />
          <!-- Welcome Message -->
          <v-row data-html2canvas-ignore>
            <v-col
              cols="12"
              class="text-right d-flex justify-end"
            >
              <v-tooltip
                bottom
                max-width="300"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <app-button
                      secondary
                      class="mr-5"
                      :loading="isLoading.other"
                      :disabled="isLoading.projects || isLoading.users"
                      @click="generatePDF"
                    >
                      Generate PDF
                    </app-button>
                  </div>
                </template>
                <span>
                  <template>
                    Generate a snapshot of your current dashboard in PDF format
                  </template>
                </span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-bottom="10"
                max-width="300"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <export-button
                      :export-data="getExportData"
                      :fields="exportFields"
                      :disabled="isLoading.projects || isLoading.users"
                      class="export-button"
                      name="data"
                    />
                  </div>
                </template>
                <span>
                  <template>
                    Export all raw data available in Excel format (projects, users, coins, services)
                  </template>
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <div>
            <v-card class="px-6 py-5 mt-4">
              <!-- Key Metrics Title -->
              <h2>
                KICKBOX Program Funnel
                <v-btn
                  icon
                  class="edit-button"
                  data-html2canvas-ignore
                  @click="keyMetricsSettingsDialog = true"
                >
                  <v-icon color="grey">
                    edit
                  </v-icon>
                </v-btn>
              </h2>
              <p>Your current state of projects and in which phases they are</p>
              <!-- Key Metrics -->
              <key-metrics />
            </v-card>
            <v-card class="px-6 py-5 mt-8">
              <!-- Analytics Title -->
              <h2
                class="dashoard-title mt-4"
                data-html2canvas-ignore
              >
                Analytics
                <v-btn
                  icon
                  class="edit-button"
                  @click="analyticsSettingsDialog = true"
                >
                  <v-icon color="grey">
                    edit
                  </v-icon>
                </v-btn>
              </h2>
              <!-- Analytics dashboard -->
              <analytics-dashboard
                ref="analyticsDashboard"
                @loadSkills="getSkillsForExpert"
                @loadTransactions="getDashboardTransactions"
              />
            </v-card>
            <!-- Settings -->
            <key-metrics-settings v-model="keyMetricsSettingsDialog" />
            <analytics-settings v-model="analyticsSettingsDialog" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { KeyMetrics, ExportButton, DashboardUtils } from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';
import skillsService from '@kickbox/common-admin/src/services/skillsService';
import Chart from 'chart.js';
import pdfService from '@/services/pdfService';
import { userService, projectService, transactionService } from '@/services';
import ProjectsDrawer from './ProjectsDrawer';
import KeyMetricsSettings from './KeyMetricsSettings';
import AnalyticsSettings from './AnalyticsSettings';
import AnalyticsDashboard from './AnalyticsDashboard';

export default {
  components: {
    KeyMetrics,
    AnalyticsDashboard,
    ProjectsDrawer,
    AnalyticsSettings,
    KeyMetricsSettings,
    ExportButton
  },
  // Load data for dashboard before route enter
  beforeRouteEnter(to, from, next) {
    const isUserLoading = userService.getUsersPerCompany();
    next((vm) => {
      if (!vm.dashboardProjects.length) {
        projectService.getDashboardProjects(true);
      }
      vm.isUserLoading = isUserLoading;
    });
  },
  data() {
    return {
      keyMetricsSettingsDialog: false,
      analyticsSettingsDialog: false,
      isSkillLoaded: false,
      isUserLoading: false,
      exportData: null
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'dashboardProjects', 'dashboardTransactions', 'users', 'company']),
    // Returns the name of the current time (Morning, Evening or Afternoon)
    greetingTime() {
      const currentHour = parseFloat(moment().format('HH'));
      if (currentHour >= 12 && currentHour <= 17) {
        return 'Afternoon';
      }
      if (currentHour >= 17) {
        return 'Evening';
      }
      return 'Morning';
    },
    // Returns the name of the user that is loged in
    userName() {
      return userService.getCurrentUserName();
    },
    exportFields() {
      const exportFields = {
        Projects: {
          Id: 'id',
          Title: 'title',
          Tagline: 'tagline',
          Description: 'description',
          Creator: 'creator.name',
          'Creator Email': 'creator.email',
          Phase: 'phase.name',
          Unit: 'projectUnit.name',
          'Created At': 'createdAt',
          'Redbox Idea Entry Date': 'redboxIdeaEntryDate',
          'Redbox Funding Entry Date': 'redboxFundingEntryDate',
          'Bluebox Entry Date': 'blueboxEntryDate',
          'Goldbox Entry Date': 'goldboxEntryDate',
          Tags: 'tags',
          'Requestet Start Month': 'startMonth',
          'Funding Goal': 'fundingGoal',
          'Coins Balance': 'coinBalance',
          Alumni: 'isAlumni',
          Rejected: 'disabled'
        },
        Users: {
          Id: 'id',
          Name: 'name',
          Email: 'email',
          Role: 'role',
          Unit: 'unit.name',
          Tagline: 'tagline',
          About: 'about',
          Tags: 'tags',
          'Email Verified': 'emailVerified',
          Language: 'language',
          'Registered at': 'createdAt'
        }
      };
      if (this.company.features[FEATURE_NAME.CAMPAIGNS]) {
        exportFields.Projects.Campaign = 'campaign.title';
      }
      if (this.company.features[FEATURE_NAME.COINS]) {
        exportFields.Coins = {
          Id: 'id',
          'Coins Amount': 'amount',
          Type: 'type',
          'Project Title': 'projectTitle',
          'Project Creator': 'projectCreator',
          'Transaction Date': 'createdAt',
          'Offer Title': 'offerTitle',
          Message: 'message'
        };
      }
      if (this.company.features[FEATURE_NAME.SERVICES]) {
        exportFields.Services = {
          Id: 'id',
          'Coins Amount': 'amount',
          'Offer Title': 'offerTitle',
          'Project Title': 'projectTitle',
          'Project Creator': 'projectCreator',
          'Request Date': 'createdAt'
        };
      }
      return exportFields;
    }
  },
  methods: {
    async getExportData() {
      try {
        await this.getDashboardTransactions();
        const firstDate = moment(this.company.parseObject.createdAt).startOf('day').toDate();
        const today = moment();
        return {
          Projects: DashboardUtils.exportProjectsData(firstDate, today),
          Users: DashboardUtils.getUsersBetweenDates(firstDate, today).map((u) => ({
            ...u,
            tags: u.tags.map((t) => t.name),
            emailVerified: u.emailVerified ? 'yes' : 'no'
          })),
          Coins: DashboardUtils.transactionsBetweenDates(firstDate, today).map((t) => ({
            ...t,
            type: this.getFormattedTransactionType(t.type)
          })),
          Services: DashboardUtils.serviceTransactionsBetweenDates(firstDate, today)
        };
      } catch (e) {
        console.error('Export data not fetched:', e);
        return false;
      }
    },
    getFormattedTransactionType(type) {
      if (type === TRANSACTION_TYPE.REQUEST) {
        return 'Offer Booking';
      }
      if (type === TRANSACTION_TYPE.CONVERT_KICK_COINS) {
        return 'Convert Kick Coins';
      }
      if (type === TRANSACTION_TYPE.ADD_PROJECT_CREDIT) {
        return 'Add Kick Coins To Project';
      }
      if (type === TRANSACTION_TYPE.REMOVE_PROJECT_CREDIT) {
        return 'Remove Kick Coins From Project';
      }
      return '';
    },
    async getSkillsForExpert() {
      if (this.users.length && !this.isSkillLoaded) {
        this.$store.dispatch('setIsLoading', { skills: true });
        const usersWithSkills = await skillsService.getSkillsForExpert(this.users);
        this.$store.dispatch('setIsLoading', { skills: false });
        this.$store.commit('setUsers', usersWithSkills);
        this.isSkillLoaded = true;
      }
      return true;
    },
    getDashboardTransactions() {
      if (!this.dashboardTransactions.length) {
        return transactionService.getDashboardTransactions();
      }
      return true;
    },
    async generatePDF() {
      this.$store.dispatch('setIsLoading', { other: true });
      Chart.defaults.global.animation.duration = 0;
      await this.getSkillsForExpert();
      await this.getDashboardTransactions();
      const options = {
        logging: false,
        scale: 2
      };
      const canvas = await this.$html2canvas(this.$refs.pdfContainer, options);
      const date = this.$refs.analyticsDashboard.$refs.analyticsNavigation.formatedDateRange;
      await pdfService.getDashboardPdf(canvas, this.company.name, date);
      Chart.defaults.global.animation.duration = 1000;
      this.$store.dispatch('setIsLoading', { other: false });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.progress-bar {
  position: fixed;
  top: 60px;
  width: 100%;
  left: 0;
  margin: 0;
  z-index: 5;
}

.export-button {
  width: 165.3px;
}
</style>
