<script>
import { Pie, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;
export default {
  name: 'LineChart',
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
          }
        }
      })
    }
  },
  mounted() {
    return this.renderChart(this.chartData, this.options);
  }
};
</script>
