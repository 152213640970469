<template>
  <v-navigation-drawer
    :value="!!projectsDrawer"
    fixed
    temporary
    right
    :width="400"
    class="projects-drawer"
    :class="{open: projectsDrawer}"
    @input="closeProjectsDrawer"
  >
    <div
      v-if="projectsDrawer"
      class="dashboard py-4 pl-4"
    >
      <div class="text-right close-button">
        <v-btn
          icon
          @click="closeProjectsDrawer(false)"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <h3
        v-ruid="'projects-drawer-title'"
        class="mb-3 ml-2"
      >
        {{ projectsDrawer.text }} - {{ projectsDrawer.type === 'campaign' ? 'Campaign' : 'Phase' }}
      </h3>
      <v-checkbox
        v-if="projectsDrawer.type === 'campaign'"
        v-model="onlyAccepted"
        color="primary"
        class="ml-2"
        label="Approved projects only"
      />
      <p class="mb-0 ml-2">
        {{ projects.length }} projects
      </p>
      <!-- Projects for phase -->
      <virtual-list
        v-model="projects"
        class="project-list mt-3"
        :class="{campaign: projectsDrawer.type === 'campaign'}"
        item-class="card"
        :size="50"
        :keeps="7"
        :data-key="'id'"
        :data-sources="projects"
        :data-component="ProjectCard"
        :extra-props="{
          openProject
        }"
      />
    </div>

    <!-- Manage Project Dialog -->
    <manage-project-dialog ref="manageProjectDialog" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import VirtualList from 'vue-virtual-scroll-list';
import { REDBOX_IDEA } from '@kickbox/common-util';
import { DashboardUtils } from '@kickbox/common-admin';
import { ManageProjectDialog } from '@/components/dialogs';
import ProjectCard from '@/components/statisticDashboard/ProjectCard';

export default {
  components: {
    VirtualList,
    ManageProjectDialog
  },
  data() {
    return {
      ProjectCard,
      onlyAccepted: false
    };
  },
  computed: {
    ...mapGetters(['projectsDrawer', 'dashboardProjects']),
    // Map the project to the format needed for the ProjectCard
    projects() {
      if (this.projectsDrawer.type === 'phase') {
        return DashboardUtils.activeProjectsByPhase(this.projectsDrawer.value)
          .sort(this.sortByPhaseDate);
      }
      return DashboardUtils.projectsByCampaign(this.projectsDrawer.value)
        .filter((project) => !this.onlyAccepted
          || project.phases.find((phase) => phase.name === REDBOX_IDEA.name))
        .sort(this.sortByPhaseDate);
    }
  },
  methods: {
    /**
     * Closes the project drawer
     * @param {boolean} value project drawer value
     */
    closeProjectsDrawer(value) {
      if (!value) {
        this.$store.commit('setProjectsDrawer', null);
      }
    },
    /**
     * Opens the project dialog
     * @param {string} projectId Project id
     */
    openProject(projectId) {
      this.$refs.manageProjectDialog.openModal(projectId);
    },
    sortByPhaseDate(a, b) {
      return b.phase.startDate - a.phase.startDate;
    }
  },
};
</script>

<style lang="scss" scoped>
.project-list {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  padding: 10px;

  ::v-deep [role=item] {
    margin-top: 10px;
  }

  &.campaign {
    max-height: calc(100vh - 190px);
  }
}

.small-project-list {
  height: calc(100vh / 2.9);
  overflow-y: auto;
}

.close-button {
  display: none;
}

@media (max-width: 600px) {
  .projects-drawer.open {
    min-width: 100%;

    .dashboard{
      padding: 20px;
    }
  }
  .close-button {
    display: block;
    margin-top: -20px;
    margin-right: -20px;
  }
}
</style>
