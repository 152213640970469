<template>
  <div :class="!sticky ? 'analytics-navigation-container' : 'small-height'">
    <div
      ref="navbar"
      class="analytics-navigation"
      :class="{sticky, white: sticky, 'full-width': !navigationDrawer}"
    >
      <!-- Tabs -->
      <v-tabs
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="activeTab"
        background-color="transparent"
        class="navigation-tabs"
      >
        <v-tab
          v-for="section in sections"
          :key="section.id"
          @click="scrollTo(section.id)"
        >
          {{ section.name }}
        </v-tab>
      </v-tabs>

      <!-- Range Picker -->
      <div class="range-picker">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
          :ranges="predefinedRanges"
          opens="left"
          show-dropdowns
          show-week-numbers
          :always-show-calendars="false"
          @update="updateDateRange"
        >
          <template #input>
            <v-text-field
              label="Select Time"
              readonly
              hide-details
              prepend-icon="date_range"
              :value="`${formatedDateRange}`"
            />
          </template>
        </date-range-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import { mapGetters } from 'vuex';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    DateRangePicker
  },
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      dateRange: {
        startDate: moment().subtract(1, 'year').startOf('month').startOf('day')
          .toDate(),
        endDate: moment().endOf('day').toDate()
      },
      navbarOffsetTop: null,
      sticky: false,
      activeTab: 0,
      sections: [],
      sectionPositionsSet: false,
      scrollHandleInAction: false,
      scrollHandleDisabled: false
    };
  },
  computed: {
    ...mapGetters(['userSettings', 'company', 'firstCreatedProject', 'navigationDrawer']),
    // Start date as moment object
    startDate() {
      return moment(this.dateRange.startDate);
    },
    // End date as moment object
    endDate() {
      return moment(this.dateRange.endDate);
    },
    /**
     * Formats tha data range into an readable format.
     * If a predefined range is selected it will return the text of the selected predefined range
     */
    formatedDateRange() {
      const selectedRange = Object.keys(this.predefinedRanges)
        .find((key) => this.startDate.isSame(this.predefinedRanges[key][0], 'day')
          && this.endDate.isSame(this.predefinedRanges[key][1], 'day'));
      if (selectedRange) return selectedRange;
      return `${this.startDate.format('DD.MM.YYYY')} - ${this.endDate.format('DD.MM.YYYY')}`;
    },
    /**
     * Calculates the predefined ranges.
     * Last 12 Months and Overall are always presend.
     * It also adds every year that the kickbox porgram is already running in the company.
     */
    predefinedRanges() {
      const defaultRanges = {
        'Last 12 months': [
          moment().subtract(1, 'year').startOf('month').startOf('day')
            .toDate(),
          moment().endOf('day').toDate()
        ],
        Overall: [
          moment(this.company.parseObject.createdAt).startOf('day').toDate(),
          moment().toDate()
        ]
      };
      const firstYear = moment(this.firstCreatedProject
        ? this.firstCreatedProject.createdAt : undefined).year();
      for (let year = new Date().getFullYear(); year >= firstYear; year -= 1) {
        defaultRanges[year] = [
          moment().set('year', year).startOf('year').toDate(),
          moment().set('year', year).endOf('year').toDate()
        ];
      }
      return defaultRanges;
    }
  },
  watch: {
    'userSettings.analytics': function onUserAnalyticsSettingsChange() {
      this.setSections();
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.setSections(true);
  },
  mounted() {
    this.navbarOffsetTop = this.$refs.navbar.offsetTop;
    window.onscroll = this.onScroll;

    /**
     * The v-model of the component already has a date range apply this.
     * Else take the default date range.
     */
    if (!this.value) {
      this.$emit('input', [this.startDate, this.endDate]);
    } else {
      this.dateRange.startDate = this.value[0].toDate();
      this.dateRange.endDate = this.value[1].toDate();
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    /**
     * Is executed every time when the users scrolls.
     * Checks if the analytics navbar is out of view. When thats the case, it applies the
     * class 'sticky' to make the navbar fixed at the top of the page
     */
    onScroll() {
      if (!this.$refs.navbar) return;
      if (window.pageYOffset >= this.navbarOffsetTop + 450) {
        this.sticky = true;
      } else {
        this.sticky = false;
        this.$nextTick(() => {
          // update the offset top because the height can change, when the key metrics change
          this.navbarOffsetTop = this.$refs.navbar.offsetTop;
        });
      }
    },
    // Updates the v-model with the new date range
    updateDateRange() {
      this.$emit('input', [this.startDate, this.endDate]);
    },
    /**
     * Scroll to the position of an element
     *
     * @param {string} id // id of html element
     */
    scrollTo(id) {
      if (!this.sectionPositionsSet) this.setSectionPositions();
      const scrollPosition = this.sections.find((section) => section.id === id).yPos;

      // Disable scroll handle when going to section via navigation
      this.scrollHandleDisabled = true;
      setTimeout(() => {
        this.scrollHandleDisabled = false;
      }, 1000);

      // IE doesn't support smooth-scroll. When smotoh-scroll is not supported by the browser,
      // jump directly to the position.
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      if (supportsNativeSmoothScroll) {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo(0, scrollPosition);
      }
    },
    handleScroll() {
      if (this.scrollHandleInAction || this.scrollHandleDisabled) return;
      window.requestAnimationFrame(() => {
        const scrollPos = window.scrollY;
        if (!this.sectionPositionsSet) this.setSectionPositions();
        for (let i = 0; i < this.sections.length; i += 1) {
          if (scrollPos > this.sections[i].yPos - 300) this.activeTab = this.sections[i].tabId;
        }
        this.scrollHandleInAction = false;
      });
      this.scrollHandleInAction = true;
    },
    setSections(isInitial = false) {
      let tabIdCounter = 0;
      this.sections = [];
      ['Projects', 'Users', 'Coins', 'Services', 'Engagement'].forEach((sectionName) => {
        if (utils.categoryEnabled(sectionName)) {
          this.sections.push({
            name: sectionName,
            id: sectionName.toLowerCase(),
            yPos: 0,
            tabId: tabIdCounter
          });
          tabIdCounter += 1;
        }
      });
      if (!isInitial) this.setSectionPositions();
    },
    setSectionPositions() {
      const bodyPosition = document.body.getBoundingClientRect().top;
      this.sections.forEach((section) => {
        const targetPosition = document.getElementById(section.id).getBoundingClientRect().top;
        const sectionRef = section;
        sectionRef.yPos = targetPosition - bodyPosition - 160;
      });
      this.sectionPositionsSet = true;
    }
  },
};
</script>

<style lang="scss" scoped>
  .analytics-navigation-container {
    height: 50px;
  }

  .small-height {
    height: 10px;
  }

  .analytics-navigation {
    display: flex;
  }

  .sticky {
    position: fixed;
    top: 64px;
    width: calc(100% - 300px);
    left: 300px;
    padding: 10px 20px 0 20px;
    height: 59px;
    z-index: 10;
    box-shadow: 0 25px 20px -20px rgb(223, 223, 223);;
  }

  .navigation-tabs {
    margin-top: 1px;
  }

  .range-picker {
    margin-left: auto;
    width: 300px;
  }

  .analytics-navigation.full-width {
    width: 100%;
    left: 0;
    top: 56px;
  }

  @media (max-width: 1023px) {
    .sticky {
      width: 100%;
      left: 0;
      top: 56px;
    }
  }

</style>

<style lang="scss">
  @import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';

  // Custom style for range picker
  .dashboard {
    .vue-daterange-picker {
      width: 300px;

      * {
        outline: none;
      }

      .show-ranges.hide-calendars {
        border: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.158);
        width: 200px;
      }

      .daterangepicker {
        top: 50px;
        max-width: calc(100vw - 50px);

        .ranges li {
          font-size: 1em;
          padding: 10px;
          color: #717171;

          &:focus {
            outline: none;
            border-right: solid 2px #2196F3;
          }

          &.active {
            background-color: rgba(33, 149, 243, 0.1);
            color: #2196F3;
            border-right: solid 2px #2196F3;
          }
        }

        .drp-buttons .btn {
          text-transform: uppercase;
          outline: none;
          font-size: 1em;
          font-weight: 400;

          &.btn-success {
            color: #2196F3;
          }

          &.btn-default {
            color: #f44336;
          }
        }
      }

      .dropdown-menu {
        border: none;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.158);
      }

      .calendars {
        &.row {
          display: flex;
          flex-wrap: nowrap;
        }

        .calendars-container {
          width: calc(100% - 200px);
        }

        .active {
          background-color: #2196F3;
          color: white;
        }
      }

      .drp-buttons {
        padding: 15px 10px;
      }
    }

    .reportrange-text {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .reportrange-text {
      padding: 0;
    }

  }

  @media screen and (max-width: 768px) {
    .dashboard .vue-daterange-picker {
      .daterangepicker {
        min-width: 300px;

        .ranges ul {
          display: block;
        }
      }

      .calendars {
        &.row {
          display: block;
          flex-wrap: wrap;
        }

        .calendars-container {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .dashboard .vue-daterange-picker .calendars-container {
      flex-wrap: wrap;
    }
  }
</style>
