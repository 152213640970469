<template>
  <div>
    <h4 class="my-4">
      Registered users over time
    </h4>
    <app-chart
      type="line"
      :chart-data="chartData"
      :loading="isLoading.users"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import AppChart from '@kickbox/common-admin/src/components/statistic-dashboard/charts/AppChart';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    AppChart
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['users', 'isLoading']),
    /**
     * returns the chart data for th e chart.js line chart
     */
    chartData() {
      const usersData = utils.getLineChartDataLabels(this.dateRange[0], this.dateRange[1]);

      // loop through each project and update the counter for the specific month
      this.users.forEach((user) => {
        const createdMonth = moment(user.createdAt).format('YYYY/MM');
        if (usersData[createdMonth] !== undefined) {
          usersData[createdMonth] += 1;
        }
      });
      return {
        labels: Object.keys(usersData),
        datasets: [{
          borderColor: '#3799FD',
          data: Object.values(usersData),
          fill: false
        }]
      };
    }
  }
};
</script>
