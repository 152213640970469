<template>
  <v-card
    :id="source.id"
    :key="source.id"
    class="kanban-item mb-3"
  >
    <!-- Nodge -->
    <div
      v-if="source.isAlumni"
      class="nodge blue-grey lighten-3"
    />

    <!-- Title, Creator, Created and Edited Dates -->
    <v-list
      class="py-0"
      two-line
    >
      <v-list-item class="pb-0 mb-0">
        <v-list-item-content>
          <v-list-item-title>{{ source.title }}</v-list-item-title>
          <v-list-item-subtitle>
            by
            <a
              class="blue--text"
              :href="`mailto:${ source.creator.email }`"
              style="text-decoration:none"
            >
              {{ source.creator.name }}
            </a>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="source.isAlumni || source.phase.isDue()">
          <v-icon :color="source.isAlumni ? 'blue-grey lighten-3' : 'red'">
            {{ source.isAlumni ? 'school' : 'notification_important' }}
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <span class="grey--text px-3">
      Created on {{ source.createdAt.format('MMM DD, YYYY') }}
    </span>

    <!-- Card Content -->
    <template v-if="showCardContent">
      <v-list dense>
        <!-- Requested Starting Month (Pending only) -->
        <v-list-item v-if="source.phase.name === phases.pending.name">
          <v-list-item-content>
            <v-list-item-title>Requested Starting Month</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text :class="source.phase.isDue() ? 'red--text' : ''">
              {{ source.startMonth.format('MMMM YYYY') }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>

        <template v-if="source.phase.name.includes('redbox')">
          <!-- Due Date (Redbox only) -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Due On</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text :class="source.phase.isDue() ? 'red--text' : ''">
                {{ source.phase.endDate.format('MMMM DD, YYYY') }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <!-- Phase Progress (Redbox only) -->
          <v-list-item>
            <v-list-item-content class="mr-3">
              <v-progress-linear
                :color="source.phase.isDue() ? 'error' : undefined"
                height="4"
                :value="(source.phase.durationLeft() * 100) / source.phase.duration()"
              />
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text :class="source.phase.isDue() ? 'red--text' : ''">
                {{ source.phase.durationLeft() > 0 ? source.phase.durationLeft() : 0 }}
                / {{ source.phase.duration() }}
                {{ Math.abs(source.phase.durationLeft()) === 1 ? 'day' : 'days' }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </template>

        <!-- Coaches -->
        <v-list-item v-if="source.coaches.length">
          <v-list-item-content class="d-flex flex-nowrap">
            {{ source.coaches.length !== 1 ? 'Coaches' : 'Coach' }}
            <div class="d-flex ml-auto coaches-avatars">
              <div
                v-for="coach in source.coaches"
                :key="coach.id"
                class="ml-1"
              >
                <avatar
                  :size="30"
                  :user="coach"
                />
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <!-- Card Actions -->
    <v-card-actions class="pa-4">
      <v-btn
        color="grey lighten-2"
        outlined
        small
        :to="{ name: 'AdminManageProjectNotes', params: { id: source.id } }"
      >
        <!-- Notes -->
        <v-icon
          color="grey lighten-1"
          class="notes-icon"
        >
          {{ source.notes.length === 0 ? 'add_comment' : 'chat_bubble_outline' }}
        </v-icon>
        <span class="font-weight-bold grey--text text--lighten-1">
          {{ source.notes.length }}
        </span>
      </v-btn>
      <v-spacer />

      <!-- Manage -->
      <v-btn
        color="blue"
        :disabled="source.isRejected"
        text
        @click="manageProject"
      >
        Manage
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Avatar } from '@/components/widgets';
import { phaseUtils } from '@/utils';

export default {
  components: {
    Avatar,
  },
  props: {
    source: {
      type: Object,
      default: () => ({})
    },
    /**
     * Function that is called when the manage button is clicked.
     *
     * Because in an virtaul list you cant listen for component events, this
     * function is only used when the projectcard is used in a virtual list.
     */
    openProject: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    phases() {
      return phaseUtils.phases;
    },

    /**
     * Determines whether card content should be shown.
     *
     * @returns {boolean}
     */
    showCardContent() {
      // Never display for Alumni projects
      if (this.source.isAlumni) return false;

      // Always display for Pending or Redbox projects
      if ([
        this.phases.pending.name,
        this.phases.redbox.name,
        this.phases.redboxFunding.name
      ].includes(this.source.phase.name)) return true;

      // Display if Bluebox or Goldbox has Coaches
      return this.source.coaches.length;
    }
  },
  methods: {
    manageProject() {
      this.$emit('manage', this.source.id);
      this.openProject(this.source.id);
    }
  }
};
</script>

<style lang="scss" scoped>
  .kanban-item {
    border-radius: 5px;
    margin-top: 8px;
  }

  .nodge {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    height: 4px;
    width: 100%;
  }

  .coaches-avatars {
    flex: unset;
  }

  .notes-icon {
    margin: 4px 8px 2px 0;
  }
</style>
