<template>
  <v-row>
    <!-- Registered Users -->
    <v-col
      cols="12"
      md="3"
    >
      <single-number
        :number="registeredUsers.length"
        text="Registered Users"
        ruid="registeredUsers"
        :loading="isLoading.users"
      />
    </v-col>
    <!-- New Kickboxers -->
    <v-col
      cols="12"
      md="3"
    >
      <single-number
        :number="newKickboxers.length"
        text="New Kickboxers"
        ruid="newKickboxers"
        :loading="isLoading.users"
      />
    </v-col>
    <!-- New Experts -->
    <v-col
      cols="12"
      md="3"
    >
      <single-number
        :number="newExperts.length"
        text="New Experts"
        ruid="newExperts"
        :loading="isLoading.users"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { KICKBOX_ROLES } from '@kickbox/common-util';
import SingleNumber from '@kickbox/common-admin/src/components/statistic-dashboard/charts/SingleNumber';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    SingleNumber
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    // New registered users in the time selected
    registeredUsers() {
      return utils.getUsersBetweenDates(
        this.dateRange[0], this.dateRange[1]
      );
    },
    // New registered kickboxsers in the time selected
    newKickboxers() {
      return this.registeredUsers
        .filter((user) => user.role.find((r) => r === KICKBOX_ROLES.KICKBOXER));
    },
    // New registered experts in the time selected
    newExperts() {
      return this.registeredUsers
        .filter((user) => user.role.find((r) => r === KICKBOX_ROLES.EXPERT));
    }
  }
};
</script>
