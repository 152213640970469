<template>
  <div class="top-engagement">
    <h4>Ranked by number of comments</h4>
    <v-data-table
      :headers="tableHeaders"
      :items="topEngagement"
      :items-per-page="10"
      :hide-default-footer="isLoading.other"
      class="table-container"
    >
      <template
        v-if="isLoading.projects"
        #body
      >
        <table-skeleton-loader
          :headers="tableHeaders"
          :rows="5"
        />
      </template>
      <template
        v-else
        #item="props"
      >
        <tr>
          <td>{{ props.item.rank }}</td>
          <td>{{ props.item.projectTitle }}</td>
          <td>{{ props.item.commentsAmount }}</td>
          <td>{{ props.item.reactionsAmount }}</td>
          <td style="text-align: right">
            <v-menu
              bottom
              left
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="openProject(props.item.projectId)"
                >
                  View Project
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #no-data>
        <p>No comments/reactions within time selected</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableSkeletonLoader from '@kickbox/common-admin/src/components/TableSkeletonLoader';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';
import { config } from '@/utils';

export default {
  name: 'TopEngagements',
  components: {
    TableSkeletonLoader
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableHeaders: [
        { text: 'Rank', value: 'rank', width: 90 },
        { text: 'Project', value: 'projectTitle', width: 150 },
        { text: 'Comments', value: 'commentsAmount', width: 120 },
        { text: 'Reactions', value: 'reactionsAmount', width: 120 },
        { sortable: false, width: 10 }
      ],
    };
  },
  computed: {
    ...mapGetters(['reactions', 'isLoading']),
    reactionsBetweenDates() {
      return utils.reactionsBetweenDates(this.dateRange[0], this.dateRange[1]);
    },
    topEngagement() {
      return this.$lodash.orderBy(this.reactionsBetweenDates, ['commentsAmount', 'reactionsAmount'], ['desc', 'desc'])
        .map((data, index) => ({ ...data, rank: index + 1 }));
    },
  },
  methods: {
    openProject(id) {
      return window.open(`${config.portalUrl}/#/project/${id}`, '_blank');
    }
  }
};
</script>
