<template>
  <settings-dialog
    :value="value"
    title="Personalize your own dashboard"
    :loading="isLoading"
    @input="$emit('input', $event)"
    @save="saveSettings"
  >
    <p>Select the Analytics you wish to see on your dashboard</p>
    <v-row no-gutters>
      <v-col
        v-for="(metrics, key) of settings"
        :key="key"
        cols="12"
        md="6"
      >
        <h4>{{ key }}</h4>
        <v-checkbox
          v-for="metric of metrics"
          :key="metric.value"
          v-model="selectedAnalytics"
          v-ruid="`analytics-setting-${metric.value}`"
          :value="metric.value"
          :label="metric.label"
          color="blue"
          class="mt-0"
        />
      </v-col>
    </v-row>
  </settings-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { DashboardUtils } from '@kickbox/common-admin';
import { userService } from '@/services';
import SettingsDialog from './SettingsDialog';

export default {
  components: {
    SettingsDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAnalytics: [],
      settings: DashboardUtils.availableAnalytics(),
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['userSettings'])
  },
  watch: {
    /**
     * Applies the user settings when the popup is opend.
     * If the user dont has set any settings yet, it applies the default settings.
     */
    value() {
      if (this.value) {
        if (this.userSettings && this.userSettings.analytics) {
          // Filter default analytics for already selected analytics.
          // Used when a featuere is turned of, but the user has already selected a
          // statistic of this feature
          this.selectedAnalytics = DashboardUtils.defaultAnalytics()
            .filter((defaultAnalytic) => this.userSettings.analytics.includes(defaultAnalytic));
        } else {
          this.selectedAnalytics = DashboardUtils.defaultAnalytics();
        }
      }
    }
  },
  methods: {
    // Saves the Settings to the database
    saveSettings() {
      this.isLoading = true;
      userService.updateUserSettings({
        analytics: this.selectedAnalytics
      }).then(() => {
        this.$emit('input', false);
      }).catch(() => {
        this.$store.dispatch('showSnackBar', { text: 'An error occurred while updating the settings.' });
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
};
</script>
