<template>
  <div>
    <!-- Navigation -->
    <analytics-navigation
      ref="analyticsNavigation"
      v-model="dateRange"
      data-html2canvas-ignore
      @input="$emit('updateDateRange', $event)"
    />

    <!-- Analytics -->
    <v-row no-gutters>
      <template v-if="categoryEnabled('Projects')">
        <v-col cols="12">
          <!-- Project stats -->
          <h3
            id="projects"
            class="mb-3 mt-4"
          >
            KICKBOX Project Conversion
          </h3>
        </v-col>
        <!-- Projects per phase -->
        <v-col
          v-if="selectedAnalytics.includes('projects-per-phase')"
          cols="12"
          :md="selectedAnalytics.includes('project-numbers') ? 9 : 12"
        >
          <project-per-phase
            :date-range="dateRange"
            :full-width="!selectedAnalytics.includes('project-numbers')"
          />
          <v-divider
            v-if="$vuetify.breakpoint.smAndDown"
            class="my-5"
          />
        </v-col>
        <v-col
          v-if="selectedAnalytics.includes('projects-per-phase')"
          cols="12"
        >
          <v-divider class="my-5" />
        </v-col>

        <!-- Projects over time -->
        <v-col
          v-if="selectedAnalytics.includes('projects-over-time')"
          cols="12"
        >
          <projects-over-time :date-range="dateRange" />
          <v-divider class="my-5" />
        </v-col>
      </template>

      <!-- User numbers -->
      <template v-if="categoryEnabled('Users')">
        <!-- User Stats -->
        <v-col cols="12">
          <h3
            id="users"
            class="user-title mt-4"
          >
            Users
          </h3>
        </v-col>
        <v-col
          v-if="selectedAnalytics.includes('user-numbers')"
          cols="12"
        >
          <user-numbers
            :date-range="dateRange"
          />
          <v-divider class="my-5" />
        </v-col>
        <!-- Users over time -->
        <v-col
          v-if="selectedAnalytics.includes('users-over-time')"
          cols="12"
        >
          <registered-users-over-time
            :date-range="dateRange"
          />
          <v-divider class="my-5" />
        </v-col>
        <!-- Users per unit -->
        <template
          v-for="(role, index) of enabledRoles"
        >
          <v-col
            v-if="index === 2 || ($vuetify.breakpoint.smAndDown && index !== 0)"
            :key="`divider-${role}`"
            cols="12"
            class="my-5"
          >
            <v-divider />
          </v-col>
          <v-col
            :key="role"
            cols="12"
            md="6"
            :class="{
              'pl-5': index % 2 === 1 && $vuetify.breakpoint.mdAndUp,
              'pr-5': index % 2 === 0 && $vuetify.breakpoint.mdAndUp
            }"
          >
            <users-per-unit
              :date-range="dateRange"
              :role="role"
              :units="units"
              :unit-colors="unitColors"
            />
          </v-col>
        </template>
        <!-- Top 10 expert skills -->
        <v-col
          v-if="selectedAnalytics.includes('top-experts-skills')
            && (enabledRoles.length % 2 === 0 || $vuetify.breakpoint.smAndDown)"
          cols="12"
        >
          <v-divider class="my-5" />
        </v-col>
        <v-col
          v-if="selectedAnalytics.includes('top-experts-skills')"
          ref="topSkills"
          cols="12"
          md="6"
        >
          <h4>Top 10 skills of experts</h4>
          <top-expert-skills
            v-if="users.length"
            :date-range="dateRange"
          />
        </v-col>
        <v-col
          v-if="enabledRoles.length"
          cols="12"
        >
          <v-divider class="my-5" />
        </v-col>
      </template>

      <!-- Coins -->
      <v-col
        v-if="categoryEnabled('Coins')"
        ref="coins"
        cols="12"
      >
        <h3
          id="coins"
          class="mt-4"
        >
          Coins
        </h3>
        <coins-numbers
          :date-range="dateRange"
        />

        <v-divider
          class="my-5"
        />
      </v-col>

      <!-- Services -->
      <v-col
        v-if="categoryEnabled('Services')"
        cols="12"
      >
        <h3
          id="services"
          class="mt-4"
        >
          Services
        </h3>
      </v-col>
      <v-col
        v-if="selectedAnalytics.includes('top-services')"
        cols="12"
        md="8"
      >
        <top-services
          :date-range="dateRange"
        />
        <v-divider
          v-if="$vuetify.breakpoint.smAndDown && selectedAnalytics.includes('service-numbers')"
          class="my-5"
        />
      </v-col>
      <v-col
        v-if="selectedAnalytics.includes('service-numbers')"
        cols="12"
        :md="selectedAnalytics.includes('top-services') ? 2 : 12"
        :offset-md="selectedAnalytics.includes('top-services') ? 2 : 12"
      >
        <services-numbers
          :date-range="dateRange"
          :full-width="!selectedAnalytics.includes('top-services')"
        />
      </v-col>

      <!-- Engagement -->
      <v-col
        v-if="categoryEnabled('Engagement')"
        cols="12"
      >
        <v-divider
          class="my-5"
        />
        <h3
          class="mt-4"
        >
          Engagement
        </h3>
      </v-col>
      <v-col
        v-if="selectedAnalytics.includes('top-engagement')"
        id="engagement"
        cols="12"
        md="8"
      >
        <top-engagements
          :date-range="dateRange"
        />
      </v-col>
      <v-col
        v-if="selectedAnalytics.includes('engagement-numbers')"
        :md="selectedAnalytics.includes('top-engagement') ? 2 : 12"
        :offset-md="selectedAnalytics.includes('top-engagement') ? 2 : 12"
      >
        <engagement-numbers
          :date-range="dateRange"
          :full-width="!selectedAnalytics.includes('top-engagement')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { BLUEBOX, GOLDBOX, KICKBOX_ROLES, REDBOX_FUNDING, REDBOX_IDEA } from '@kickbox/common-util';
import { mapGetters } from 'vuex';
import companyService from '@kickbox/common-admin/src/services/companyService';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';
import AnalyticsNavigation from './AnalyticsNavigation';
import ProjectPerPhase from './stats/ProjectsPerPhase';
import ProjectsOverTime from './stats/ProjectsOverTime';
import UserNumbers from './stats/UserNumbers';
import RegisteredUsersOverTime from './stats/RegisteredUsersOverTime';
import UsersPerUnit from './stats/UsersPerUnit';
import TopExpertSkills from './stats/TopExpertSkills';
import CoinsNumbers from './stats/CoinsNumbers';
import TopServices from './stats/TopServices';
import ServicesNumbers from './stats/ServicesNumbers';
import TopEngagements from './stats/TopEngagements';
import EngagementNumbers from './stats/EngagementNumbers';

export default {
  components: {
    AnalyticsNavigation,
    ProjectPerPhase,
    ProjectsOverTime,
    UserNumbers,
    RegisteredUsersOverTime,
    UsersPerUnit,
    TopExpertSkills,
    CoinsNumbers,
    TopServices,
    ServicesNumbers,
    TopEngagements,
    EngagementNumbers
  },
  data() {
    return {
      dateRange: [
        moment().subtract(1, 'year').startOf('day').startOf('month'),
        moment().endOf('day')
      ],
      phases: [REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX],
      roles: [KICKBOX_ROLES.VIEWER, KICKBOX_ROLES.KICKBOXER, KICKBOX_ROLES.EXPERT],
      units: [],
      settingsDialog: false,
      skillObserver: null,
      transactionsObserver: null
    };
  },
  computed: {
    ...mapGetters(['userSettings', 'users', 'company']),
    /**
     * returns an object with every unit as key and color as value
     * used that a unit always has the same color
     */
    unitColors() {
      const unitColors = {};
      const colors = utils.getColors(this.units.length);
      this.units.forEach((unit, index) => {
        unitColors[unit.name] = colors[index];
      });
      return unitColors;
    },
    // Returns only the roles (viewer, kickboxer or expert) that the user has enabled
    enabledRoles() {
      return this.roles.filter((role) => this.selectedAnalytics.includes(`${role.toLowerCase()}-per-unit`));
    },
    selectedAnalytics() {
      return utils.selectedAnalytics();
    }
  },
  async mounted() {
    companyService.getUnitsByCompany(this.company.parseObject)
      .then((units) => {
        this.units = units;
      });
    this.skillObserver = new IntersectionObserver(this.getSkillsForExpert,
      {
        threshold: 0.3
      });
    if (this.skillObserver) {
      this.skillObserver.observe(this.$refs.topSkills);
    }
    this.transactionsObserver = new IntersectionObserver(this.getDashboardTransactions,
      {
        threshold: 0.3
      });
    if (this.transactionsObserver) {
      this.transactionsObserver.observe(this.$refs.coins);
    }
  },
  beforeDestroy() {
    if (this.transactionsObserver) {
      this.transactionsObserver.disconnect();
    }
    if (this.skillObserver) {
      this.skillObserver.disconnect();
    }
  },
  methods: {
    categoryEnabled(category) {
      return utils.categoryEnabled(category);
    },
    async getSkillsForExpert(entries) {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        this.$emit('loadSkills');
        this.skillObserver.disconnect();
      }
    },
    getDashboardTransactions(entries) {
      const { isIntersecting } = entries[0];
      if (isIntersecting) {
        this.$emit('loadTransactions');
        this.transactionsObserver.disconnect();
      }
    }
  }
};
</script>
