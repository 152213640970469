<template>
  <v-dialog
    :value="value"
    width="800"
    @input="$emit('input', $event)"
  >
    <v-card class="dashboard">
      <!-- Card header -->
      <v-card-title
        class="headline"
        primary-title
      >
        <h2>{{ title }}</h2>
      </v-card-title>
      <v-divider />

      <!-- Content -->
      <v-card-text class="card-text py-4 px-6">
        <slot />
      </v-card-text>

      <v-divider />

      <!-- Card actions -->
      <v-card-actions>
        <app-button
          secondary
          cancel
          @click="$emit('input', false)"
        >
          Cancel
        </app-button>
        <v-spacer />
        <!-- Needs a v-if for automated testing. Else the button is multible times in the html and
        can lead to errors -->
        <app-button
          v-if="value"
          v-ruid="'save-settings'"
          :loading="loading"
          @click="$emit('save')"
        >
          Save
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.card-text {
  max-height: 500px;
  overflow-y: auto;
}
</style>
