<template>
  <v-row>
    <v-col
      cols="12"
      :md="fullWidth ? 3 : 12"
    >
      <single-number
        text="Comments"
        :number="comments"
        :loading="isLoading.projects"
      />
    </v-col>
    <v-col
      cols="12"
      :md="fullWidth ? 3 : 12"
    >
      <single-number
        text="Reactions"
        :number="reactions"
        :loading="isLoading.projects"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleNumber from '@kickbox/common-admin/src/components/statistic-dashboard/charts/SingleNumber';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    SingleNumber
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'reactions']),
    reactionsBetweenDates() {
      return utils.reactionsBetweenDates(this.dateRange[0], this.dateRange[1]);
    },
    comments() {
      return this.getAmount('commentsAmount');
    },
    reactions() {
      return this.getAmount('reactionsAmount');
    }
  },
  methods: {
    getAmount(type) {
      let amount = 0;
      this.reactionsBetweenDates.forEach((r) => { amount += r[type]; });
      return amount;
    }
  }
};
</script>
