<template>
  <div>
    <div class="header-flex">
      <h4 class="mt-4">
        Projects over time
      </h4>
      <!-- Checkboxes -->
      <div
        class="only-accepted-projects"
        data-html2canvas-ignore
      >
        <v-checkbox
          v-if="company.features[FEATURE_NAME.CAMPAIGNS]"
          v-model="showCampaigns"
          label="Show Campaigns"
          color="blue"
          class="mr-4"
        />
        <v-checkbox
          v-model="includePending"
          label="Include pending projects"
          color="blue"
        />
      </div>
    </div>
    <!-- Chart -->
    <app-chart
      type="line"
      :chart-data="projectsData"
      legend
      :loading="isLoading.projects"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { REDBOX_IDEA } from '@kickbox/common-util';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import AppChart from '@kickbox/common-admin/src/components/statistic-dashboard/charts/AppChart';
import utils from '@kickbox/common-admin/src/components/statistic-dashboard/utils';

export default {
  components: {
    AppChart
  },
  props: {
    dateRange: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      includePending: false,
      showCampaigns: false,
      FEATURE_NAME
    };
  },
  computed: {
    ...mapGetters(['dashboardProjects', 'company', 'isLoading']),
    // Create the object needed for the chart.js line chart
    projectsData() {
      const projectData = utils.getLineChartDataLabels(this.dateRange[0], this.dateRange[1]);

      const campaigns = {};
      /*
       * Filter the projects to have a redbox phase when the user has selected the option
       * "Only approved"
       */
      this.dashboardProjects.filter((project) => !project.disabled && (
        this.includePending
        || project.phases.find((phase) => phase.name === REDBOX_IDEA.name)
      ))
        .forEach((project) => {
          const createdMonth = project.createdAt.format('YYYY/MM');
          // Check if the project is in the selected date range
          if (projectData[createdMonth] !== undefined) {
            // Updates the counter of the spesific month
            projectData[createdMonth] += 1;

            // Checks if the project has a campaign and the "Show Camapigns" option is selected
            if (project.campaign && this.showCampaigns) {
              // Add the default labels for the campaing if they are not set yet
              if (campaigns[project.campaign.title] === undefined) {
                campaigns[project.campaign.title] = utils.getLineChartDataLabels(
                  this.dateRange[0], this.dateRange[1]
                );
              }
              // Updates the counter for the campaign and month
              campaigns[project.campaign.title][createdMonth] += 1;
            }
          }
        });

      const labels = Object.keys(projectData);
      const data = Object.values(projectData);

      // Create the dataset for the created projects
      const datasets = [
        {
          label: this.includePending ? 'Pending and Approved Projects' : 'Approved Projects',
          borderColor: '#3799FD',
          data,
          fill: false,
          pointBackgroundColor: '#3799FD',
        },
      ];

      // Create the datasets for campaigns
      const colors = utils.getColors(campaigns.lengh);
      Object.keys(campaigns).forEach((campaign, index) => {
        datasets.push({
          borderColor: colors[index],
          pointBackgroundColor: colors[index],
          data: Object.values(campaigns[campaign]),
          fill: false,
          label: campaign
        });
      });
      return {
        labels,
        datasets
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.header-flex {
  display: flex;
}

.only-accepted-projects {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
}
</style>
